<template>
  <div class='preferences mt-3 p-3'>
    <h1>
      Minhas preferências
    </h1>

    <hr class='mt-5 mb-4' />

    <section>
      <p
        v-if='preferences.addAgentsLegalConsultationTopList'
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja definir que agentes adicionados à consulta legal sejam posicionados no início da lista?
      </p>

      <p
        v-else
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja definir que agentes adicionados à consulta legal sejam posicionados no final da lista?
      </p>
      <b-form-radio-group
        v-model='preferences.addAgentsLegalConsultationTopList'
        @change='submit()'
      >
        <b-form-radio
          :value='true'
        >
          <span :style="preferences.addAgentsLegalConsultationTopList === true ? 'font-weight: 700' : ''">Sim</span>
        </b-form-radio>

        <b-form-radio
          :value='false'
        >
          <span :style="preferences.addAgentsLegalConsultationTopList === false ? 'font-weight: 700' : ''">Não</span>
        </b-form-radio>
      </b-form-radio-group>
    </section>

    <section class='mt-4'>
      <p
        v-if='preferences.atTheBeginningReport'
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja adicionar no início do relatórios informações sobre a origem (aba, hora e usuário responsável) da
        exportação?
      </p>

      <p
        v-else
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja adicionar no final do relatórios informações sobre a origem (aba, hora e usuário responsável) da
        exportação?
      </p>

      <b-form-radio-group
        v-model='preferences.atTheBeginningReport'
        @change='submit()'
      >
        <b-form-radio
          :value='true'
        >
          <span :style="preferences.atTheBeginningReport === true ? 'font-weight: 700' : ''">Sim</span>
        </b-form-radio>

        <b-form-radio
          :value='false'
        >
          <span :style="preferences.atTheBeginningReport === false ? 'font-weight: 700' : ''">Não</span>
        </b-form-radio>
      </b-form-radio-group>
    </section>

    <section class='mt-4'>
      <p
        v-if='preferences.showAnalysisEffectiveProtectionPermanentExhibition'
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja que as frases de orientação relativas à proteção eficaz e exposição permanente apareçam no documento impresso?
      </p>

      <p
        v-else
        class='mt-1 mr-2 mb-1 label-form'
      >
        Deseja que as frases de orientação relativas à proteção eficaz e exposição permanente apareçam no documento impresso?
      </p>

      <b-form-radio-group
        v-model='preferences.showAnalysisEffectiveProtectionPermanentExhibition'
        @change='submit()'
      >
        <b-form-radio
          :value='true'
        >
          <span :style="preferences.showAnalysisEffectiveProtectionPermanentExhibition === true ? 'font-weight: 700' : ''">Sim</span>
        </b-form-radio>

        <b-form-radio
          :value='false'
        >
          <span :style="preferences.showAnalysisEffectiveProtectionPermanentExhibition === false ? 'font-weight: 700' : ''">Não</span>
        </b-form-radio>
      </b-form-radio-group>
    </section>

    <section class='mt-5'>
      <p
        v-if='preferences.atTheBeginningReport'
        class='mt-1 mr-2 mb-2 label-form'
      >
        Selecionar paleta de cores de documentos exportados:
      </p>

      <section
        style='display: grid;  grid-template-columns: repeat(auto-fill, minmax(25%, auto));'
      >
        <b-form-radio-group
          class=''
          v-model='preferences.colorPalette'
          v-for='pallete in palettesColors'
          @change='submit()'
          :key='pallete.key'
        >
          <section
            class='pallete mr-3 p-2'
            :style="preferences.colorPalette && preferences.colorPalette.key === pallete.key ? 'border: 1px solid #022245;': 'border: 1px solid #C0C0C0;'"
          >
            <b-form-radio
              :value='pallete'
            >
              <span
                class='radio-text'
                :style="preferences.colorPalette && preferences.colorPalette.key === pallete.key ? 'color: #022245;': 'color: #C0C0C0;'"
              >
                Paleta 0{{ pallete.key }}
              </span>
            </b-form-radio>

            <section class='d-flex mt-3 justify-content-between'>
              <section :style='`background: ${pallete.header}`' class='color'></section>
              <section :style='`background: ${pallete.title}`' class='color'></section>
              <section :style='`background: ${pallete.text}`' class='color'></section>
            </section>

            <hr class='mt-3 mb-3' />

            <section>
              <span class='text-use'>Exemplo em uso</span>

              <section class='preview'>
                <section class='preview-item-header' :style='`background: ${pallete.header}`'>
                  <p class='pt-1'>cabeçalho</p>
                </section>
                <section class='preview-item-title' :style='`background: ${pallete.title}`'>
                  <p class='pt-1'>título</p>
                </section>
                <section class='preview-item-text' :style='`background: ${pallete.text}`'>
                  <p class='pt-1'>texto</p>
                </section>
              </section>
            </section>
          </section>
        </b-form-radio-group>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserPreferences',
  data() {
    return {
      preferences: {
        addAgentsLegalConsultationTopList: false,
        atTheBeginningReport: false,
        showAnalysisEffectiveProtectionPermanentExhibition: null,
        colorPalette: {},
        _id: null
      },
      palettesColors: [
        {
          key: 1,
          header: '#022245',
          title: '#B2E7FF',
          text: '#FFFFFF'
        },
        {
          key: 2,
          header: '#04A8F3',
          title: '#B6E8FF',
          text: '#FFFFFF'
        },
        {
          key: 3,
          header: '#191919',
          title: '#AEAEAE',
          text: '#FFFFFF'
        }
      ]
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Minhas preferências',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    await this.getUserPreferences()
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('userPreferences', {
      handleGetUserPreferences: 'handleGetUserPreferences',
      handleCreateUserPreferences: 'handleCreateUserPreferences',
      handleChangUserPreferences: 'handleChangUserPreferences'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async getUserPreferences() {
      try {
        const { userPreferences } = await this.handleGetUserPreferences(this.user.id)
        if (userPreferences) {
          this.preferences = userPreferences
        }
        console.log(this.preferences)
      } catch (e) {

      }
    },
    async submit() {
      let response = {}
      let preferences = {
        addAgentsLegalConsultationTopList: this.preferences.addAgentsLegalConsultationTopList,
        atTheBeginningReport: this.preferences.atTheBeginningReport,
        colorPalette: this.preferences.colorPalette,
        showAnalysisEffectiveProtectionPermanentExhibition: this.preferences.showAnalysisEffectiveProtectionPermanentExhibition,
      }

      if (this.preferences._id) {
        preferences._id = this.preferences._id
        response = await this.handleChangUserPreferences(preferences)
      } else {
        preferences.user = this.user.id
        response = await this.handleCreateUserPreferences(preferences)
      }
      const { data, status } = response
      let message = data.message
      let variant = 'danger'
      if (status === 200 || status === 201) {
        message = data.message
        variant = 'success'
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.getUserPreferences()
      const sessionId = localStorage.getItem('sessionId')
      const user = (await this.$api.get(`users/${sessionId}`)).data
      await this.$store.dispatch('user/updateUser', user)
    }
  }
}
</script>

<style lang='scss' scoped>
.preferences {
  background: #FFFFFF !important;
  border: 1px solid #C0C0C0;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;
    color: #262626;
  }

  hr {
    background: #C0C0C0;
  }

  .label-form {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: #262626;
  }

  .checkbox-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #262626;
  }


  .pallete {

    border-radius: 8px;

    .radio-text {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;

    }

    .color {
      width: 56px;
      height: 56px;
      border: 1px solid #C0C0C0;
      border-radius: 50%;
    }

    .text-use {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #022245;
    }

    .preview {
      text-align: center;
      border-radius: 8px;
      border: 1px solid #C0C0C0;
      .preview-item-header {
        height: 40px;
        border-radius: 8px 8px 0 0;
        color: #FFFFFF;
      }
      .preview-item-title {
        height: 40px;
        color: #262626;
      }
      .preview-item-text {
        height: 40px;
        color: #262626;
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
</style>
